var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pr-5 mb-4",attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary","to":"/main/user-admin/create"}},[_vm._v(" "+_vm._s(_vm.$_strings.userAdmin.CREATE_USERADMIN_BUTTON_LABEL)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('filter-list',{attrs:{"filters":_vm.filters},on:{"fetchData":_vm.setFilter}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.userAdminListTotalEntry,"options":_vm.pagination,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': _vm.$_item_per_page,
      disablePagination: _vm.isLoading,
      disableItemsPerPage: _vm.isLoading
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.activated",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[(_vm.userAccess.canUpdate)?_c('v-col',{attrs:{"cols":9}},[_c('v-select',{staticClass:"text-body-2",attrs:{"disabled":!item.action || item.companyUserId === _vm.myUserInfo.companyUserId || item.activated === false,"value":item.activated,"items":_vm.userActivationOption,"loading":item.isLoading,"solo":"","hide-details":"","dense":"","rounded":""},on:{"input":function (val) { return _vm.toggleUserActivation(item, val); },"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_vm._e(),(_vm.userAccess.canDelete)?_c('v-col',{attrs:{"cols":"auto"}},[(item.companyUserId !== _vm.myUserInfo.companyUserId)?_c('v-btn',{attrs:{"icon":"","color":"error","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.deleteUser(item); })($event)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1):_vm._e()],1):_vm._e()],1)]}},{key:"item.actionColumn",fn:function(ref){
    var item = ref.item;
return [(item.action && item.companyUserId !== _vm.myUserInfo.companyUserId && item.activated && _vm.userAccess.canUpdate)?_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.resetPassword(item); })($event)}}},[_vm._v(" "+_vm._s(_vm.$_strings.userAdmin.RESETPASSWORD_BTN_LABEL)+" ")]):_vm._e(),(!item.activated && _vm.userAccess.canUpdate)?_c('v-btn',{attrs:{"color":"warning","rounded":"","small":"","min-width":"130"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.resendEmail(item); })($event)}}},[_vm._v(" Resend Email ")]):_vm._e(),(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.userAdmin.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }