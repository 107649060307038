<template>
  <v-container fluid>
    <v-row
      dense
      justify="space-between"
      align="center"
      class="pr-5 mb-4"
    >
      <v-col cols="12" sm="4">
        <v-btn
          color="primary"
          to="/main/user-admin/create"
          v-if="userAccess.canCreate"
        >
          {{$_strings.userAdmin.CREATE_USERADMIN_BUTTON_LABEL}}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row justify="end" dense>
          <v-col cols="auto">
            <filter-list :filters="filters" @fetchData="setFilter" />
          </v-col>
          <!-- <v-col cols="12" sm="4">
            <v-select
              v-model="filterByStatus"
              :items="statusItems"
              :placeholder="$_strings.userAdmin.STATUS_USER_ADMIN"
              outlined
              dense
              hide-details
              class="caption"
              @change="filterStatus"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details
              outlined
              :placeholder="$_strings.userAdmin.SEARCH_USERADMIN_INPUT_PLACEHOLDER"
              ref="search"
              class="caption"
              @keyup.enter="search"
              v-model="searchText"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="search">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      item-key="id"
      :server-items-length="userAdminListTotalEntry"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page,
        disablePagination: isLoading,
        disableItemsPerPage: isLoading
      }"
    >
      <template v-slot:[`item.activated`]="{item}">
        <v-row no-gutters>
          <v-col :cols="9" v-if="userAccess.canUpdate">
            <v-select
              :disabled="!item.action || item.companyUserId === myUserInfo.companyUserId || item.activated === false"
              :value="item.activated"
              :items="userActivationOption"
              :loading="item.isLoading"
              solo
              hide-details
              dense
              rounded
              class="text-body-2"
              @input="val => toggleUserActivation(item, val)"
              @click.stop.prevent
            ></v-select>
          </v-col>
          <v-col cols="auto" v-if="userAccess.canDelete">
            <v-btn
              v-if="item.companyUserId !== myUserInfo.companyUserId"
              icon
              color="error"
              fab
              small
              @click.stop.prevent="() => deleteUser(item)"
            >
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actionColumn`]="{item}">
        <v-btn
          v-if="item.action && item.companyUserId !== myUserInfo.companyUserId && item.activated && userAccess.canUpdate"
          color="primary"
          rounded
          small
          @click.stop.prevent="() => resetPassword(item)"
        >
          {{$_strings.userAdmin.RESETPASSWORD_BTN_LABEL}}
        </v-btn>
        <v-btn
          v-if="!item.activated && userAccess.canUpdate"
          color="warning"
          rounded
          small
          min-width="130"
          @click.stop.prevent="() => resendEmail(item)"
        >
          Resend Email
        </v-btn>
        <v-tooltip v-if="userAccess.canUpdate" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click.stop.prevent="edit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.userAdmin.PAGE_NAME}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import FilterList from './FilterList.vue';

export default {
  name: 'user-admin-list',
  components: {
    FilterList,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.userAdmin.NAME_TABLE_HEADER_LABEL,
          value: 'username',
        },
        {
          text: this.$_strings.userAdmin.EMAIL_TABLE_HEADER_LABEL,
          value: 'email',
        },
        {
          text: this.$_strings.userAdmin.ROLE_TABLE_HEADER_LABEL,
          value: 'roleRolesName',
          width: '14vw',
        },
        {
          text: this.$_strings.userAdmin.STATUS_TABLE_HEADER_LABEL,
          value: 'activated',
          width: '17vw',
        },
        {
          text: '',
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          width: '200px',
          sortable: false,
        },
      ],
      filters: {
        username: this.$route.query.username || '',
        email: this.$route.query.email || '',
        activated: this.$route.query.activated ? this.$route.query.activated === 'true' : '',
        rolesId: +this.$route.query.rolesId || '',
      },
      pagination: defaultPagination(),
      userAdminListSize: 10,
      userAdminListTotalEntry: 0,
      userActivationOption: [
        {
          text: this.$_strings.userAdmin.ACTIVATED_OPTION_LABEL,
          value: true,
        },
        {
          text: this.$_strings.userAdmin.INACTIVATED_OPTION_LABEL,
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      const showRowStatus = this.userAccess.canDelete || this.userAccess.canUpdate;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (showRowStatus) {
          return header;
        }
        return header.value !== 'activated';
      });
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchUserAdmins();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchUserAdmins();
    },
    search() {
      if (this.searchText !== this.$route.query.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      this.setFilter();
    },
    filterStatus() {
      if (this.filterByStatus !== this.$route.query.status) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status: this.filterByStatus,
          },
        });
      }
      this.setFilter();
    },
    async edit(item) {
      const { canUpdate } = this.userAccess;
      if (!canUpdate) return;
      try {
        this.$root.$loading.show();
        const result = await this.$_services.userAdmins.fetchDetail(item.companyId, item.companyUserId);
        this.$router.push({
          name: 'user-admin-edit-page',
          params: {
            companyId: item.companyId,
            userId: item.companyUserId,
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchUserAdmins() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filters,
      };

      if (filters.username) filters.username = `qLike(${filters.username})`;
      if (filters.email) filters.email = `qLike(${filters.email})`;
      try {
        this.isLoading = true;
        const res = await this.$_services.userAdmins.fetchList(this.skipEmptyStringObject(filters));
        this.items = res.data.contents;
        this.userAdminListTotalEntry = res.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async resetPassword(item) {
      try {
        this.$root.$loading.show();
        await this.$_services.userAdmins.resetPassword(item);
        this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async resendEmail(item) {
      try {
        this.$root.$loading.show();
        await this.$_services.userAdmins.resendEmail(item);
        this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESEND_USER_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    deleteUser(item) {
      this.$dialog.warning({
        text: this.$_strings.userAdmin.DELETE_USER_CONFIRM_MESSAGE,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (userRes) {
          this.$root.$loading.show();
          this.$_services.userAdmins.deleteUser(item)
            .then((res) => {
              this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_DELETE_USER_MESSAGE_TEXT);
              this.fetchUserAdmins();
            }).finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
    toggleUserActivation(item, val) {
      this.$set(item, 'isLoading', true);
      this.$_services.userAdmins.createEdit({ ...item, activated: val }, item.companyUserId)
        .then((res) => {
          this.fetchUserAdmins();
        }).catch(() => {
          this.$nextTick(() => {
            this.$set(item, 'activated', !val);
          });
        }).finally(() => {
          this.$delete(item, 'isLoading');
        });
    },
  },
};
</script>
